/* share_button.css */

.share-button {
    display: inline-block;
    margin: 10px;
  }
  
  .share-button .ms-Button {
    background-color: var(--button-bg);
    color: var(--button-color);
  }
  
  .share-button .ms-Button:hover {
    background-color: var(--button-hover-bg);
    color: var(--button-hover-color);
  }