.image-block {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center image and caption */
  text-align: center; /* Center caption text */
  margin-bottom: 1rem; /* Add spacing between images */
}

.image-block img {
  max-width: 100%; /* Ensure image scales responsively */
  height: auto;
}

.image-block .fui-Caption1 {
  margin-top: 0.5rem; /* Add spacing between image and caption */
}