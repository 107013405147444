.article-renderer > div {
  margin: 16px 0;
}

.article-renderer ul,
.article-renderer ol {
  margin: 16px 0;
}

.article-renderer figure {
  margin: 16px 0;
}

.article-renderer blockquote {
  margin: 16px 0;
  padding: 8px 16px;
  border-left: 4px solid #ccc;
  background: #f9f9f9;
}