/* components/blog/post_page.css */


.title-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.post-page {
    margin: 10px
}

.post-content {
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
    color: #323130; /* FluentUI neutralPrimary color */
    max-width: 800px; /* Adjust the max-width to your preference */
    margin: auto;
}

/* Additional styling for headings, paragraphs, and other elements */
.post-content h1, .post-content h2, .post-content h3, .post-content h4, .post-content h5, .post-content h6 {
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 1.5em;
}

.post-content p {
    margin-bottom: 1em;
}

/* Responsive image container */
.feature-image-container {
    width: 100%;
    max-width: 100%;
    height: 400px;
    overflow: hidden;
}